<template>
    <div class="container" style="padding-bottom: 20px;">
        <div class="row">
            <div class="col-1">
                <router-link to="/"><img alt="QRCodeExperts.net" src="./assets/logo-qrcodeexperts.png"
                        class="logo" /></router-link>
            </div>
            <div class="col-11">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <h2>QRCodeExperts</h2>
                        </div>
                        <div class="row">
                            <Navbar :key="$route.fullPath" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px">
            <router-view />
        </div>
    </div>
</template>

<script>
import Navbar from "./components/Navbar.vue"

export default {
    components: { Navbar },
}
</script>

<style>
body {
    background: #b0b9bb;
    margin: 8px;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #b0b9bb;
}

.logo {
    width: 80px;
}
</style>
