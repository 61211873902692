<template>
    <p>Authorizing</p>
</template>

<script>
import { useRouter } from 'vue-router';
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()

export default {
    mounted() {
        const router = useRouter();
        const auth = this.$route.query.auth;
        let next = "/notauthorized";
        if (auth != null) {
            //do lookups into userbase authenticate and come back with an id and 
            //  an auth key (with expiration if needed)
            let authCookie = {
                auth: auth,
                owneruserid: auth,
            }
            cookies.set("theAuthCookie", JSON.stringify(authCookie))
            next = "/"
            //window.location.reload();
            //this.$router.go(0)
            //next("/");
        } else {
            cookies.remove("theAuthCookie")
        }
        this.interval = setTimeout(
            function () {
                router.push({ path: next });
            },
            50
        );
    }
}
</script>