<template>
    <div v-if="code && user">
        <div v-if="outputFormat">
            <div class="wizi">
                <div v-if="code.offerImage" class="wiziImage">
                    <img :src="code.offerImage" />
                </div>
                <div v-else class="wiziText biztext">
                    <h1>{{ this.code.BusName }}</h1>
                </div>
                <div v-if="code.usemapbutton && code.usemapbutton == 1 || code.usemapbutton && code.usemapbutton == 1 || code.usemapbutton && code.usemapbutton == 1"
                    class="wizibuttons">
                    <div class="row">
                        <div class="btn-group btn-group-justified" role="group" aria-label="Ad Action Buttons">
                            <div v-if="code.usemapbutton && code.usemapbutton == 1">
                                <div class="btn-group" role="group">
                                    <a :href="code.locationurl">
                                        <button type="button" class="btn btn-default" id="admap">MAP</button>
                                    </a>
                                </div>
                            </div>
                            <div v-if="code.usemapbutton && code.usemapbutton == 1">
                                <div class="btn-group" role="group">
                                    <a style="text-decoration: none; color:white;" :href="'tel:' + code.phone">
                                        <button type="button" class="btn btn-default" id="adcall">CALL</button>
                                    </a>
                                </div>
                            </div>
                            <div v-if="code.usemapbutton && code.usemapbutton == 1">
                                <div class="btn-group" role="group">
                                    <a style="text-decoration:none; color:white;" :href="code.desturl">
                                        <button type="button" class="btn btn-default" id="adweb">WEB</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else> <!-- non-wizi -->
            <div class="row border rounded border-primary">
                <div class="col-12">
                    <h1>Code Details</h1>
                </div>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-3"><b><u>Key</u></b></div>
                <div class="col-9"><b><u>Value</u></b></div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Owner</b></div>
                <div class="col-9 border rounded border-secondary">{{ owneruserid }}<br />{{ ownername
                    }}<br />{{ ownerusername }}</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Style</b></div>
                <div class="col-9 border rounded border-secondary">{{ codeStyle }} ({{ code.selectedStyleSelectOption
                    }})</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Code ID's</b></div>
                <div class="col-9 border rounded border-secondary"><a href="/code/{{ code.codeid }}">{{ code.codeid
                        }}</a><br />{{ code.shortcodeid }}</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Business Name:</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.BusName }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 1" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Redirect URL</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.redirecturl }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 2 && code.desturl" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Web URL</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.desturl }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 2 && code.phone" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Phone Number</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.phone }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 2 && code.location" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Location</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.location }}</div>
            </div>
        </div>
    </div>
    <div v-else>
        <p>Loading code details... {{ codeidQuery }} - {{ codeid }}</p>
    </div>
</template>

<script>

const dpiSelectOptions = [
    {
        name: "Web (96dpi)",
        value: 96
    },
    {
        name: "Print (384dpi)",
        value: 384
    },
]
const styleSelectOptions = [
    {
        name: "Redirect Only",
        value: 1
    },
    {
        name: "Traditional",
        value: 2
    },
]


export default {
    name: 'CodeDetails',
    display: "Codes Details",
    props: ["codeid"],
    data() {
        return {
            imgsrc: "",
            code: null,
            user: null,
            codeidQuery: this.codeid,
            outputFormat: this.$route.query.of,
            codeStyle: 'None',
            owneruserid: '',
            ownerusername: '',
            ownername: '',

        }
    },
    mounted() {
        if (this.outputFormat == 1) {
            console.log("outputFormat 1")
            /*
            //jquery
            let jqueryScript = document.createElement('script')
            jqueryScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js')
            document.head.appendChild(jqueryScript)
            var jqueryCSS = document.createElement("link")
            jqueryCSS.setAttribute("rel", "stylesheet")
            jqueryCSS.setAttribute("type", "text/css")
            jqueryCSS.setAttribute("href", "https://ajax.googleapis.com/ajax/libs/jquery/2.1.4/jquery.min.js")
            document.getElementsByTagName("head")[0].appendChild(jqueryCSS)
            let jqueryUI = document.createElement('script')
            jqueryScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js')
            document.head.appendChild(jqueryUI)
            //BootStrap
            var bootstrapCSS = document.createElement("link")
            bootstrapCSS.setAttribute("rel", "stylesheet")
            bootstrapCSS.setAttribute("type", "text/css")
            bootstrapCSS.setAttribute("href", "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css")
            document.getElementsByTagName("head")[0].appendChild(bootstrapCSS)
            var bootstrapTheme = document.createElement("link")
            bootstrapTheme.setAttribute("rel", "stylesheet")
            bootstrapTheme.setAttribute("type", "text/css")
            bootstrapTheme.setAttribute("href", "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap-theme.min.css")
            document.getElementsByTagName("head")[0].appendChild(bootstrapTheme)
            let bootstrapScript = document.createElement('script')
            bootstrapScript.setAttribute('src', 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js')
            document.head.appendChild(bootstrapScript)
            */
        } else {
            console.log("no outputFormat")
        }

        fetch("http://localhost:3000/codes?codeid=" + this.codeidQuery)
            .then(res => res.json())
            .then(data => {
                this.code = data[0]
            })
            .then(() => {
                console.log(`code loaded ${JSON.stringify(this.code)}`)
                this.codeStyle = this.code && this.code.selectedStyleSelectOption ? styleSelectOptions.find(styleSelectOption => {
                    return styleSelectOption.value ==
                        this.code.selectedStyleSelectOption
                }).name : 'None';
            })
            .then(() => {
                console.log(`code owneruserid ${this.code.owneruserid}`)
                fetch("http://localhost:3000/users?id=" + this.code.owneruserid)
                    .then(res => res.json())
                    .then(data => {
                        this.user = data[0]
                        this.owneruserid = this.user.id
                        this.ownerusername = this.user.username
                        this.ownername = `${this.user.fname} ${this.user.lname}`
                    })
                    .then(() => {
                        console.log(`user loaded ${JSON.stringify(this.user)}`)
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            })
            .catch(err => {
                console.log(err.message)
            })
        console.log(`code data loaded...`);

    },
    components: {

    }

}
</script>

<style>
body {
    margin: 0;
    font-size: 13px;
    line-height: 1.231;
}

body,
button,
input,
select,
textarea {
    font-family: sans-serif;
    color: #222;
}

a:hover,
a:active {
    outline: 0;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

wizi {
    background-color: #f5f5f5;
}

div.wiziImage {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

Footer {
    background-color: #f5f5f5;
}

.whiteboxshadow {
    color: black;
    background-color: #f5f5f5;
    box-shadow: 4px 4px 4px #919191;
}

.buttonrow {
    height: 80px;
}

.adtoprow {
    height: 5px;
}

.biztext {
    font-size: 1em;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.bizinfocontainer {
    height: 100%;
    width: 100%;
}

.bizoffercontainer {
    height: 40%;
    width: 100%;
}

.btn {
    font-weight: bold;
    /*color:red;*/
    font-size: 2em;
}

/* DivTable.com */
.divTable {
    display: flex;
    text-align: left;
    display: table;
    width: 50%;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    /*border: 1px solid #999999;*/
    display: table-cell;
    padding: 3px 10px;
    width: 50%;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}

.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}

.divTableBody {
    display: table-row-group;
}
</style>