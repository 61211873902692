//const uri = process.env.APIURI
const uri = "http://localhost:3000/" //MUST BE FIXED


async function getUser(id) {
    return new Promise(resolve => {
        fetch(uri + `users/?id=${id}`)
            .then(res => res.json())
            .then(data => { resolve(data) })
            .catch(err => {
                let msg = `Error Loading User ${id} - ${JSON.stringify(err)}`
                this.loadingCodesMessage += msg;
                console.log(msg);
                resolve(null)
            })
    })
}

async function getAndNormalizeCodes(theUsers) {
    return new Promise(resolve => {
        let returnObj = {
            theUsers: theUsers,
            theCodes: [],
            theMessages: ''
        }
        try {
            fetch(uri + "codes")
                .then(res => res.json())
                .then(async data => {
                    let removedCodes = []
                    returnObj.theCodes = data.filter(code => code.codeid && !code.codeid.toLowerCase().includes('null') && code.redirecturl && !code.redirecturl.toLowerCase().includes('null'))
                    //go get relevant users
                    if (returnObj.theCodes.length > 0) {
                        //loop through codes to find additional users
                        await Promise.all(returnObj.theCodes.map(async (code) => {
                            try {
                                let ownerUserId = code.owneruserid
                                let theOwnerUser = returnObj.theUsers.find(user => user.id == ownerUserId)
                                if (theOwnerUser && theOwnerUser.owner_user_id && theOwnerUser.owner_user_id != 0) {
                                    let theOwnerOwnerUser = getUser(theOwnerUser.owner_user_id)
                                    returnObj.theUsers.push(theOwnerOwnerUser)
                                    code.hasManager = true;
                                } else {
                                    removedCodes.push(code.id)
                                }
                            } catch (err) {
                                console.log(`review codes error ${JSON.stringify(err)}`)
                                //returnObj.theMessages += `error analyzing codes @ codeid ${code.id} err ${JSON.stringify(err)}`
                            }
                        }))
                        returnObj.theCodes = returnObj.theCodes.filter(code => !removedCodes.includes(code.id))
                        if (returnObj.theCodes.length <= 0) {
                            returnObj.theMessages += "No Codes Loaded"
                        }
                        resolve(returnObj)
                    } else {
                        returnObj.theMessages += `No Codes Loaded`
                        resolve(returnObj)
                    }
                })
                .catch(err => {
                    returnObj.theMessages += `Loading Codes Failed. ${JSON.stringify(err)}`
                    console.log(JSON.stringify(err))
                    resolve(returnObj)
                })
        } catch (err) {
            returnObj.theMessages += `error Loading Codes ${JSON.stringify(err)}`
            console.log(JSON.stringify(err));
            resolve(returnObj)
        }
    })
}

module.exports = {
    getUser,
    getAndNormalizeCodes
}